import React from 'react';
import Myroutes from './Components/Myroutes';


const App = () => {
  return (
    <div>
      <Myroutes />
    </div>
  );
};

export default App;
